import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import Main from "./components/Main";
import OptionsModal from "./components/OptionsModal";
import ImportWallet from "./components/ImportWallet";
import QrModal from "./components/QrModal";

const App = () => {
  const [importWallet, setImportWallet] = useState(false);
  const [modal, setModal] = useState(false);
  const [walletCn, setWalletCn] = useState(false);

  // Step 2: Use useEffect to update state after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      // Step 3: Update state after 3 seconds
      setModal(true);
    }, 3000);

    // Step 4: Cleanup the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures that the effect runs only once

  return (
    <div>
      <div className={modal || importWallet || walletCn ? "blur-md" : 'blur-none'}>
      <Navbar />
      <Main setModal={setModal}/>
      </div>
      {modal && (
        <OptionsModal
          setModal={setModal}
          setImportWallet={setImportWallet}
          setWalletCn={setWalletCn}
        />
      )}
      {walletCn && (
        <QrModal
          importWallet={importWallet}
          setImportWallet={setImportWallet}
          setWalletCn={setWalletCn}
          setModal={setModal}
        />
      )}
      {importWallet && (
        <ImportWallet
          importWallet={importWallet}
          setImportWallet={setImportWallet}
          setModal={setModal}
        />
      )}
    </div>
  );
};

export default App;
