import React from "react";
import logo from "../assets/log.jpg";
const Main = ({setModal}) => {
  return (
    <div >
      <div className="flex-col flex items-center justify-center py-2">
        <img src={logo} alt="logo" className="w-32 rounded-full" />
        <div className="h-[46vh] flex items-center justify-center">
          <button onClick={() => setModal(true)} className="bg-blue-700 text-xl  w-80 px-8 py-3 rounded-full p-2 text-white">
            Connect Wallet
          </button>
        </div>
      </div>
    </div>
  );
};

export default Main;
