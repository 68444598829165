import React from "react";
import logo from "../assets/logo.png";
import { AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {
  return (
    <div className="bg-white py-4 px-2 ">
      <div className="max-w-[1200px] mx-auto flex justify-between items-center">
        <div className="flex items-center gap-2">
          <img src={logo} alt="logo" className="w-6" />
          <p className="font-semibold text-blue-500">Tezos Wallet</p>
        </div>
        <p className="text-indigo-500 md:block hidden font-bold">
          Import Wallet
        </p>
        <AiOutlineMenu className="text-blue-700 md:hidden block text-xl font-bold" />
      </div>
    </div>
  );
};

export default Navbar;
