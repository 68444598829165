import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import walletConnect from "../assets/walletConnect.svg";
import coinbase from "../assets/coinbase.svg";
import wall4 from "../assets/wall14.svg";
import trezor from "../assets/trezor.PNG";
import ledger from "../assets/ledger.png";
import metamask from "../assets/metamask.png";

const Modal = ({ setModal, setImportWallet, setWalletCn }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000); // 3000 milliseconds = 3 seconds

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div className="transition-10 duration-50 justify-center items-center flex fixed lg:top-0 top-5 inset-0  z-50 outline-0 ">
      <div className="lg:w-[350px] w-[22.6rem] btnBg py-4 lg:px-4 md:px-9 px-4 rounded-sm bg-white drop-shadow-2xl">
        {loading ? (
          <div className="flex justify-center min-h-[400px] items-center">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between font-[600] text-lg ">
              <p className="text-blue-500">Connect a wallet</p>
              <AiOutlineClose
                onClick={() => setModal(false)}
                className="font-[700] text-blue-500 cursor-pointer "
              />
            </div>
            <div className="my-4">
              <div
                className="flex my-4 justify-between items-center bg-blue-100 hover:opacity-80 p-3 gap-5 rounded-md cursor-pointer"
                onClick={() => setImportWallet(true)}
              >
                <p className="lg:text-mdt ext-sm font-[500]">Browser Wallet</p>
                <img src={wall4} alt="dakjdga" className="w-6" />
              </div>
              <div
                className="flex my-4 justify-between items-center bg-blue-100 hover:opacity-80 p-3 gap-5 rounded-md cursor-pointer"
                onClick={() => setWalletCn(true)}
              >
                <p className="lg:text-md text-sm font-[500]">WalletConnet </p>
                <img src={walletConnect} alt="dakjdga" className="w-6" />
              </div>
              <div
                className="flex my-4 justify-between items-center bg-blue-100 hover:opacity-80 p-3 gap-5 rounded-md cursor-pointer"
                onClick={() => setImportWallet(true)}
              >
                <p className="lg:text-mdte xt-sm font-[500]">Metamask Wallet</p>
                <img className="w-6" src={metamask} alt="dakjdga" />
              </div>

              <div
                className="flex my-4 justify-between items-center bg-blue-100 hover:opacity-80 p-3 gap-5 rounded-md cursor-pointer"
                onClick={() => setImportWallet(true)}
              >
                <p className="lg:text-md text-sm font-[500]">Ledger Wallet</p>
                <img src={ledger} alt="dakjdga" className="w-6" />
              </div>
              <div
                className="flex my-4 justify-between items-center bg-blue-100 hover:opacity-80 p-3 gap-5 rounded-md cursor-pointer"
                onClick={() => setImportWallet(true)}
              >
                <p className="lg:text-md text-sm font-[500]">Trezor Wallet</p>
                <img src={trezor} alt="dakjdga" className="w-6" />
              </div>
              <div
                className="flex my-4 justify-between items-center bg-blue-100 hover:opacity-80 p-3 gap-5 rounded-md cursor-pointer"
                onClick={() => setImportWallet(true)}
              >
                <p className="lg:text-mdte xt-sm font-[500]">Coinbase Wallet</p>
                <img src={coinbase} alt="dakjdga" className="w-6" />
              </div>
              <p className="text-blue-500 text-sm">
                Track wallet balance in read-only mode
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
